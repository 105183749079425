// header_and_button max 75rem

.header_and_button {

  .header_and_button_container {

    h2 {
      font-size: 2rem;
    }

    .hollow_button {
      font-size: 1.125rem;
      padding: 0.75rem 1.5rem;
    }
  }
}