// basic styles
//@import "style_base.scss";

// breakpoint max-width: 75rem
@media screen and (max-width: 75rem) {
  @import "ContentElements/audioplayer/audioplayer_max_75rem.scss";
  @import "ContentElements/blog_detail/blog_detail_max_75rem.scss";
  @import "ContentElements/calendar/calendar_max_75rem.scss";
  @import "ContentElements/form/form_max_75rem.scss";
  @import "ContentElements/header_and_button/header_and_button_max_75rem.scss";
  @import "ContentElements/image_textbox/image_textbox_max_75rem.scss";
  @import "ContentElements/logo_gallery/logo_gallery_max_75rem.scss";
}
