// form max 75rem

.form {

  .form_container {

    .form_image {
      padding-left: 2rem;
    }
  }
}