//logo_gallery max 75rem

.logo_gallery {

  .logo_gallery_container {

    .logo_gallery_images {

      .logo_gallery_image_container {
        height: calc((20vw - 2rem) / 2);
        padding: 1rem;
        margin: 2rem 0;
      }
    }
  }
}