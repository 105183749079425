// image_textbox max 75rem

.image_textbox {

  .textbox {

    .textbox_header {

      h2 {
        position: relative;
        font-size: 2.5rem;

        em {
          font-size: 3rem;
        }
      }
    }
  }
}