// blog_detail max 75rem

.blog_detail {

  .blog_detail_container {

    .blog_detail_part {

      .quote {

        p {
          font-size: 2rem;
        }
      }

      .blog_detail_imageonly {

        img {
          width: calc(100% + 2rem);
          margin-left: -2rem;
        }
      }

      .blog_detail_imageandtext {

        .image {

          img {
            width: calc(100% + 2rem);
            margin-left: -2rem;
          }
        }
      }

      .blog_detail_imageandquote {

        .image {
          width: calc(60% + 2rem);
          margin-left: -2rem;
        }

        &.quoteleft {

          .image {
            width: 60%;
          }
        }
      }
    }
  }
}