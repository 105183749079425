// audioplayer max 75rem

.audioplayer {

  .audioplayer_container {

    .audioplayer_text {
      padding-left: 2rem;
    }
  }
}