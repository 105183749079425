//calendar max 75rem

.calendar {

  .calendar_container {

    .calendar_person {

      .calendar_person_image_container {
        padding-right: 2rem;
      }
    }
  }
}